import request from "./request";
import {Provider} from "../models/provider";

const getProviders = () => request( {
    url: "/imageProvider",
}, (data: any[]) => {
    const providerList: Provider[] = [];
    data.forEach((providerJSON: any) => {
        providerList.push(Provider.createFromJson(providerJSON));
    });
    return providerList;
});

const updateProvider = (providerId: Number, providerDetails: {[key: string]: string}) => request({
    url:  `/imageProvider/${providerId}`,
    method: "PUT",
    data: providerDetails,
});

export { getProviders, updateProvider };
