import { Chapter } from "./chapter";

export class Manga {
	private _id: string;
	private _name: string;
	private _customUrl: string;
	private _coverUrl: string;
	private _path: string;
	private _description: string | null;
	private _year: number | null;
	private _status: string | null;
	private _following: boolean | null;
	private _chapters: Chapter[] = [];

	constructor(id: string, name: string, customUrl: string, coverUrl: string, path: string,
				description: string | null, year: number | null, status: string | null,
				following: boolean | null, chapters: Chapter[]) {
		this._id = id;
		this._name = name;
		this._customUrl = customUrl;
		const url = new URL(coverUrl);
		this._coverUrl = url.pathname;
		this._path = path;
		this._description = description;
		this._year = year;
		this._status = status;
		this._following = following;
		this._chapters = chapters;
	}

	static createFromJson(json: any) {
		const chapters: Chapter[] = [];

		if(json.chapters) {
			json.chapters.forEach((chapterJSON: any) => {
				chapters.push(Chapter.createFromJson(chapterJSON));
			})
		}

		return new Manga(json.id, json.name, json.custom_url, json.cover_url, json.path,
			json.description, json.year, json.status, json.following, chapters);
	}

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	get customUrl(): string {
		return this._customUrl;
	}

	set customUrl(value: string) {
		this._customUrl = value;
	}

	get coverUrl(): string {
		return this._coverUrl;
	}

	set coverUrl(value: string) {
		this._coverUrl = value;
	}

	get id(): string {
		return this._id;
	}

	set id(value: string) {
		this._id = value;
	}

	get path(): string {
		return this._path;
	}

	set path(value: string) {
		this._path = value;
	}

	get description(): string | null {
		return this._description;
	}

	set description(value: string | null) {
		this._description = value;
	}

	get year(): number | null {
		return this._year;
	}

	set year(value: number | null) {
		this._year = value;
	}

	get status(): string | null {
		return this._status;
	}

	set status(value: string | null) {
		this._status = value;
	}

	get following(): boolean | null {
		return this._following;
	}

	set following(value: boolean | null) {
		this._following = value;
	}

	get chapters(): Chapter[] {
		return this._chapters;
	}

	set chapters(value: Chapter[]) {
		this._chapters = value;
	}
}
