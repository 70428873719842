export class Provider {
	private _id: Number;
	private _url: string;
	private _needsCheck: boolean;

	constructor(id: number, url: string, needsCheck: boolean) {
		this._id = id;
		this._url = url;
		this._needsCheck = needsCheck;
	}

	static createFromJson(json: any) {
		return new Provider(json.id, json.url, json.needs_check);
	}

	get id(): Number {
		return this._id;
	}

	set id(value: Number) {
		this._id = value;
	}

	get url(): string {
		return this._url;
	}

	set url(value: string) {
		this._url = value;
	}

	get needsCheck(): boolean {
		return this._needsCheck;
	}

	set needsCheck(value: boolean) {
		this._needsCheck = value;
	}
}
