import axios from 'axios';

const client = axios.create({ baseURL: 'https://manga.azgaroth.be/api' });
client.defaults.withCredentials = true;
client.defaults.headers.common['Cache-Control'] = 'no-cache,no-store,must-revalidate';
client.defaults.headers.common['Pragma'] = 'no-cache,no-store,must-revalidate';
client.defaults.headers.common['Expires'] = 0;

const request = async ({...options}, onSuccess?: Function) => {
    if(!onSuccess){
        onSuccess = (data: any) => data;
    }

    try {
        const response = await client(options);
        return onSuccess(response.data);
    } catch (error: any) {
        throw error
    }
}

export default request;
