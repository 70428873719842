import "./Login.css";
import { useLogin } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { FormEvent } from "react";

function Login() {
    const year = new Date().getFullYear();
    const mutation = useLogin();
    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent) =>  {
        event.preventDefault();
        mutation.mutate(new FormData(event.target as HTMLFormElement), {
            onSuccess(...rest) {
                navigate(-1);
            }
        });
    }

    return (
        <div className="login">
            <main className="form-signin text-center">
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="username"/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                        <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className="checkbox mb-3">
                        <label>
                            <input type="checkbox" name="rememberMe"/> Remember me
                        </label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                    <p className="mt-5 mb-3 text-muted">&copy; 2021 – {year}</p>
                </form>
            </main>
        </div>
    )
}

export default Login;
