export class Chapter {
	private _manga_id: string;
	private _chapter: number;
	private _season: Number;
	private _downloaded: boolean;
	private _downloadAttempts: Number;
	private _isBlacklisted: boolean;

	constructor(manga_id: string, chapter: number, season: Number, downloaded: boolean, downloadAttempts: Number) {
		this._manga_id = manga_id;
		this._chapter = chapter;
		this._season = season;
		this._downloaded = downloaded;
		this._downloadAttempts = downloadAttempts;
		this._isBlacklisted = this._downloadAttempts === 3;
	}

	static createFromJson(json: any) {
		return new Chapter(json.manga_id, json.chapter, json.season, json.downloaded, json.download_attempts);
	}

	get manga_id(): string {
		return this._manga_id;
	}

	set manga_id(value: string) {
		this._manga_id = value;
	}

	get chapter(): number {
		return this._chapter;
	}

	set chapter(value: number) {
		this._chapter = value;
	}

	get season(): Number {
		return this._season;
	}

	set season(value: Number) {
		this._season = value;
	}

	get downloaded(): boolean {
		return this._downloaded;
	}

	set downloaded(value: boolean) {
		this._downloaded = value;
	}

	get downloadAttempts(): Number {
		return this._downloadAttempts;
	}

	set downloadAttempts(value: Number) {
		this._downloadAttempts = value;
	}

	get isBlacklisted(): boolean {
		return this._isBlacklisted;
	}

	set isBlacklisted(value: boolean) {
		this._isBlacklisted = value;
	}
}
