import { Modal } from "react-bootstrap";

function EntityDeleteModal(props: { show: boolean, handleClose: Function }) {
    return (
        <Modal show={props.show} onHide={() => props.handleClose(false)} size="lg">
            <Modal.Header closeButton>
                <h4 className="modal-title" id="modal-title">Delete Entity</h4>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this entity?
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-secondary"
                        onClick={() => props.handleClose(false)}>Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={() => props.handleClose(true)}>Delete</button>
            </Modal.Footer>
        </Modal>
    )
}

export default EntityDeleteModal;
