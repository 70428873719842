import request from "./request";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

const isLoggedInKey: string[] = ['isLoggedIn']

const useLogin = () => {
    const queryClient: QueryClient = useQueryClient();

    const setLoggedIn = useCallback(
        (data: boolean) => queryClient.setQueryData(isLoggedInKey, data),
        [queryClient]
    );

    return useMutation({
        mutationFn: authenticate,
        onSuccess: (...rest) => setLoggedIn(true),
    });
}

const useLogout = () => {
    const queryClient: QueryClient = useQueryClient();

    const setLoggedIn = useCallback(
        (data: boolean | null) => queryClient.setQueryData(isLoggedInKey, data),
        [queryClient]
    );

    return useMutation({
        mutationFn: logout,
        onSuccess: (...rest) => {
            queryClient.clear();
            return setLoggedIn(false);
        },
    })
}


const authenticate = (data: FormData) => request( {
    url: "/auth",
    method: "POST",
    data: data,
})

const logout = (data: boolean) => request( {
    url: "/auth/logout",
    method: "POST",
})

export { useLogin, useLogout }
