import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createManga, deleteManga, downloadChapter, getDetails, getMangas, unBlacklistChapter } from "../../api/manga";
import "./MangaDetails.css";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Manga } from "../../models/manga";
import { Chapter } from "../../models/chapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCheckCircle, faDownload, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import MangaCreateModal from "../../modals/manga-create-modal/MangaCreateModal";
import EntityDeleteModal from "../../modals/entity-delete-modal/EntityDeleteModal";

function MangaDetails() {
    const { data } = useQuery({ queryKey: ['manga-list'], queryFn: () => getMangas("name")});
    const { mangaId } = useParams();
    const [manga, setManga]: [manga: Manga | undefined, setManga: Function] = useState();
    const [showCreateModal, setShowCreateModal]: [showCreateModal: boolean, setShowCreateModal: Function] = useState(false);
    const [showDeleteModal, setShowDeleteModal]: [showDeleteModal: boolean, setShowDeleteModal: Function] = useState(false);
    const [forceMangaUpdate, setForceMangaUpdate]: [forceMangaUpdate: boolean, setForceMangaUpdate: Function] = useState(false);
    const queryClient = useQueryClient();

    const addMutation = useMutation({
        mutationFn: createManga,
        onSuccess: (...rest) => {
            queryClient.invalidateQueries({ queryKey: ['manga-list'] });
            setForceMangaUpdate(true);
        },
    });

    const deleteMutation = useMutation({
        mutationFn: deleteManga,
        onSuccess: (...rest) => {
            queryClient.invalidateQueries({ queryKey: ['manga-list']});
            setForceMangaUpdate(true);
        },
    });

    const downloadChapterMutation = useMutation({
        mutationFn: ({chapter, season = 0}: {chapter: Number, season: Number}) => downloadChapter(mangaId!, chapter, season),
        onSuccess: (...rest) => {
            queryClient.invalidateQueries({ queryKey: ['manga-list']});
            setForceMangaUpdate(true);
        }
    });

    const unBlacklistChapterMutation = useMutation({
        mutationFn: ({chapter, season = 0}: {chapter: Number, season: Number}) => unBlacklistChapter(mangaId!, chapter, season),
        onSuccess: (...rest) => {
            queryClient.invalidateQueries({ queryKey: ['manga-list']});
            setForceMangaUpdate(true);
        }
    })

    const mangaDetails = useQuery({
        queryKey: ['manga-details'],
        queryFn: () => getDetails(mangaId!),
        enabled: false,
    });

    const handleClickAdd = () => {
        setShowCreateModal(true);
    }

    const handleClickDelete = () => {
        setShowDeleteModal(true);
    }

    const handleDeleteModalClose = (shouldBeDeleted: boolean) => {
        if(shouldBeDeleted) {
            deleteMutation.mutate(mangaId!);
        }
        setShowDeleteModal(false);
    }

    const handleCreateModalClose = (mangaDetails: {[key: string]: string} | null) => {
        if(mangaDetails) {
            addMutation.mutate(mangaDetails);
        }
        setShowCreateModal(false);
    }

    useEffect(() => {
        if(!manga || forceMangaUpdate) {
            let result = null;
            if(data) {
                result = data.find((m: Manga) => m.id === mangaId);
            }
            if(mangaDetails && !result) {
                mangaDetails.refetch();
                result = mangaDetails.data;
            }
            setManga(result);
        }
    }, [data, manga, mangaId, mangaDetails, forceMangaUpdate]);

    return (
        <div className="container-fluid">
            <div className="row">
                {manga && (
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <br/>
                        <div className="card">
                            <div className="manga-details card-body">
                                <img src={manga.coverUrl} style={{height: '408px'}} alt="" />
                                <div className="manga-content">
                                    <div className="manga-title">
                                        <h1 className="h2">{manga.name}</h1>
                                        {!manga.following && (
                                            <button className="btn btn-dark" onClick={handleClickAdd}>
                                                Add
                                            </button>
                                        )}
                                        {manga.following && (
                                            <button className="btn btn-outline-secondary btn-sm">
                                                <FontAwesomeIcon icon={faArrowsRotate}/>
                                            </button>
                                        )}
                                        {manga.following && (
                                            <button className="btn btn-danger btn-sm" onClick={handleClickDelete}>
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                    <span><b>{manga.description}</b></span>
                                    <span>Year: {manga.year}</span>
                                    <span>Status: {manga.status}</span>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="manga-chapters card">
                            <div className="list-group list-group-flush">
                                {manga.chapters.map((chapter: Chapter) => (
                                    <div className="list-group-item manga-chapter" key={chapter.chapter}>
                                        <span>Chapter {chapter.chapter}</span>
                                        <div className="chapter-icon">
                                            {chapter.isBlacklisted && (
                                                <FontAwesomeIcon icon={faCheckCircle} onClick={() => unBlacklistChapterMutation.mutate({chapter: chapter.chapter, season: chapter.season})}/>
                                            )}
                                            {!chapter.isBlacklisted && chapter.downloaded && (
                                                <FontAwesomeIcon icon={faFloppyDisk}/>
                                            )}
                                            {!chapter.isBlacklisted && !chapter.downloaded && (
                                                <FontAwesomeIcon icon={faDownload} onClick={() => downloadChapterMutation.mutate({chapter: chapter.chapter, season: chapter.season})}/>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </main>
                )}
            </div>
            <MangaCreateModal manga={manga!} show={showCreateModal} handleClose={handleCreateModalClose}/>
            <EntityDeleteModal show={showDeleteModal} handleClose={handleDeleteModalClose}/>
        </div>
    )
}

export default MangaDetails;
