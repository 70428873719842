import request from "./request";
import { Manga } from "../models/manga";

const getMangas = (orderBy?: string, desc?: boolean) => {
    let queryParams: {[key: string]: any} = {};
    if(orderBy) {
        queryParams['order_by'] = orderBy;
    }
    if(desc) {
        queryParams['desc'] = desc;
    }

    return request( {
        url: "/manga",
        params: queryParams,
    }, (data: any[]) => {
        const mangaList: Manga[] = [];
        data.forEach((mangaJSON: any) => {
            mangaJSON["following"] = true;
            mangaList.push(Manga.createFromJson(mangaJSON));
        });
        return mangaList;
    });
}

const searchManga = (title: string) => request({
    url: `/manga/search/${title}`,
}, (data: any[]) => {
    const mangaList: Manga[] = [];
    data.forEach((mangaJSON: any) => {
        mangaList.push(Manga.createFromJson(mangaJSON));
    });
    return mangaList;
});

const getDetails = (mangaId: string) => request({
    url: `/manga/${mangaId}/details`,
}, (data: any) => {
    return Manga.createFromJson(data);
});

const downloadAllMangas = () => request({
    url: `/manga/download`,
    method: "GET",
});

const downloadManga = (mangaId: string) => request({
    url: `/manga/${mangaId}/download`,
    method: "GET",
});

const downloadChapter = (mangaId: string, chapter: Number, season: Number = 0) => request({
    url: `/manga/${mangaId}/chapters/${season}-${chapter}/download`,
    method: "GET",
});

const createManga = (mangaDetails: {[key: string]: string}) => request({
    url: "/manga",
    method: "POST",
    data: mangaDetails,
});

const unBlacklistChapter = (mangaId: string, chapter: Number, season: Number = 0) => request({
    url: `/manga/${mangaId}/chapters/${season}-${chapter}/unblacklist`,
    method: "PUT",
});

const deleteManga = (mangaId: string) => request({
    url: `/manga/${mangaId}`,
    method: "DELETE",
});

export {
    getMangas,
    getDetails,
    searchManga,
    downloadAllMangas,
    downloadManga,
    downloadChapter,
    unBlacklistChapter,
    createManga,
    deleteManga
};
