import { Modal } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { Manga } from "../../models/manga";

function MangaCreateModal(props: {manga: Manga, show: boolean, handleClose: Function}) {
    const [state, setState]: [state: {[key: string]: string}, setState: Function] = useState({id: "", name: "", path: "", canonicalized: ""});

    const generateMangaCanonicalName = (mangaName: string) => {
        const cleanMangaName = mangaName.replace(/[^a-zA-Z0-9 -]/g, '');
        let mangaNameWords = cleanMangaName.replace(/  +/g, ' ').replace(/[^a-zA-Z0-9]/g, ' ').split(" ").filter((word: string) => word !== "");
        for (let i = 0; i < mangaNameWords.length; i++) {
            mangaNameWords[i] = mangaNameWords[i][0].toUpperCase() + mangaNameWords[i].substring(1);
        }
        return mangaNameWords.join("-");
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>, field: string) => {
        const updatedState: {[key: string]: string} = Object.assign({}, state);
        updatedState[field] = event.target.value;
        setState(updatedState);
    }

    useEffect(() => {
        if(props.manga) {
            setState({
                id: props.manga.id,
                name: props.manga.name,
                path: props.manga.name,
                canonicalized: generateMangaCanonicalName(props.manga.name),
            });
        }
    }, [props.manga]);

    return (
        <Modal show={props.show} onHide={() => props.handleClose(null)} size="lg">
            <Modal.Header closeButton>
                <h4 className="modal-title" id="modal-title">Add Manga</h4>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label htmlFor="mangaId">Manga ID</label>
                        <div className="input-group">
                            <input id="mangaId" className="form-control" name="mangaId" value={state.id} disabled/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mangaName">Manga Name</label>
                        <div className="input-group">
                            <input id="mangaName" className="form-control" name="mangaName" value={state.name}
                                   onChange={(e) => handleOnChange(e, 'name')}/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mangaPath">Manga Path</label>
                        <div className="input-group">
                            <input id="mangaPath" className="form-control" name="mangaPath" value={state.path}
                                   onChange={(e) => handleOnChange(e, 'path')}/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mangaCanonicalized">Manga Canonicalized</label>
                        <div className="input-group">
                            <input id="mangaCanonicalized" className="form-control" name="mangaCanonicalized"
                                   value={state.canonicalized}
                                   onChange={(e) => handleOnChange(e, 'canonicalized')}/>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-secondary" onClick={() => props.handleClose(null)}>Cancel</button>
                <button type="button" className="btn btn-dark" onClick={() => props.handleClose(state)}>Add</button>
            </Modal.Footer>
        </Modal>
    )
}

export default MangaCreateModal;
