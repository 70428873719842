import { useQuery } from "@tanstack/react-query";
import { getMangas } from "../../api/manga";
import "./Home.css";
import { useNavigate } from "react-router-dom";

function Home() {
    const { isLoading, data } = useQuery({ queryKey: ['manga-list'], queryFn: () => getMangas("name")});
    const navigate = useNavigate();

    const onMangaClick = (mangaId: string) => {
        navigate(`manga/${mangaId}`);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <main className="col-md-12 ms-sm-auto col-lg-12">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Manga List</h1>
                    </div>

                    {!isLoading && data && (
                        <div className="manga-list">
                            {data.map((manga: any) => (
                                <div className="card manga-card" onClick={() => onMangaClick(manga.id)} key={manga.id}>
                                    <img className="card-img-top" style={{height: '408px'}} src={manga.coverUrl} alt=""/>
                                    <div className="card-body">
                                        <h5 className="card-title">{manga.name}</h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </main>
            </div>
        </div>
    )
}

export default Home
