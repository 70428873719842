import './App.css';
import Header from './components/header/Header';
import Login from './components/login/Login';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Home from "./components/home/Home";
import MangaDetails from "./components/manga-details/MangaDetails";
import Providers from "./components/providers/Providers";

function App() {
    const onSuccess = (data: any) => {
        queryClient.setQueryData(["isLoggedIn"], true);
        return data;
    }

    const onError = (error: any) => {
        if(error.response?.status === 401) {
            queryClient.clear();
            queryClient.setQueryData(["isLoggedIn"], false);
            window.location.pathname = '/login';
        }
        return error;
    }

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onSuccess: onSuccess,
            onError: onError
        }),
        mutationCache: new MutationCache({
            onSuccess: onSuccess,
            onError: onError
        }),
        defaultOptions: {
            queries: {
                retry: false,
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/manga/:mangaId" element={<MangaDetails />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/providers" element={<Providers />}/>
                </Routes>
                <ReactQueryDevtools />
            </BrowserRouter>
        </QueryClientProvider>
  );
}

export default App;
