import './Header.css';
import { useLogout } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import React, { LegacyRef, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { searchManga } from "../../api/manga";
import { Manga } from "../../models/manga";

function Header() {
    const logoutMutation = useLogout();
    const navigate = useNavigate();
    let searchedTitle = "";
    const [dropdownShown, setDropdownShown]: [dropdownShown: boolean, setDropdownShown: Function] = useState(false);
    const { data, refetch } = useQuery({
        queryKey: ['search-manga'],
        queryFn: () => searchManga(searchedTitle),
        enabled: false,
    });

    useEffect(() => {
        if(data) {
            setDropdownShown(true);
        }
    }, [data]);

    const handleClick = (event: any) =>  {
        logoutMutation.mutate(false, {
            onSuccess(...rest) {
                navigate("login");
            }
        });
    }

    const handleEnter = (event: any) => {
        if(event.keyCode === 13) {
            searchedTitle = event.target.value;
            refetch();
        }
    }

    const handleOnToggle = (nextshow: boolean) => {
        setDropdownShown(nextshow);
    }

    const onMangaClick = (mangaId: string) => {
        setDropdownShown(false);
        navigate(`manga/${mangaId}`);
    }

    const CustomToggle = React.forwardRef(({}, ref: LegacyRef<any>) => (
        <input ref={ref} className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" onKeyDown={handleEnter}/>
    ));

    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
            <Navbar.Brand onClick={() => navigate("")}>MangaScans</Navbar.Brand>
            <Nav className="me-auto">
                <Nav.Link onClick={() => navigate("")}>Home</Nav.Link>
                <Nav.Link onClick={() => navigate("providers")}>Providers</Nav.Link>
            </Nav>
            <Dropdown className="dropdown search-dropdown w-75" show={dropdownShown} onToggle={handleOnToggle}>
                <Dropdown.Toggle id="dropdown" as={CustomToggle}>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {data && data.map((manga: Manga) => (
                        <Dropdown.Item href="#" key={manga.id} onClick={() => onMangaClick(manga.id)} className="dropdown-element">
                            <img src={manga.coverUrl} className="searched-manga-cover" alt=""/>
                            <h5>{manga.name}</h5>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Nav className="ms-auto">
                <Nav.Link onClick={handleClick}>Sign Out</Nav.Link>
            </Nav>
        </Navbar>
    )
}

export default Header
