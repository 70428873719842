import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import "./Providers.css";
import {getProviders, updateProvider} from "../../api/provider";
import {Provider} from "../../models/provider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function Providers() {
    const { isLoading, data } = useQuery({ queryKey: ['provider-list'], queryFn: getProviders});
    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: ({providerId, providerDetails}: {providerId: Number, providerDetails: {[key: string]: any}}) => updateProvider(providerId, providerDetails),
        onSuccess: (...rest: any[]) => {
            queryClient.invalidateQueries({ queryKey: ['provider-list'] });
        },
    });

    const handleUnblacklistClick = (providerId: Number) => {
        updateMutation.mutate({providerId, providerDetails: {needs_check: false}});
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <main className="col-md-12 ms-sm-auto col-lg-12">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Provider List</h1>
                    </div>

                    {!isLoading && data && (
                        <table className="table table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">URL</th>
                                    <th scope="col">Needs Check</th>
                                    <th scope="col"/>
                                </tr>
                            </thead>
                            <tbody>
                            {data.map((provider: Provider) => (
                                <tr>
                                    <td>{provider.url}</td>
                                    <td>
                                        <input type="checkbox" checked={provider.needsCheck} disabled/>
                                    </td>
                                    <td>
                                        {provider.needsCheck && (
                                            <FontAwesomeIcon icon={faCheckCircle} onClick={() => handleUnblacklistClick(provider.id)}/>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </main>
            </div>
        </div>
    )
}

export default Providers
